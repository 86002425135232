$(document).ready(function () {
	var accordion = $(".accordion"),
		accordionBtn = $(".accordion__btn"),
		accordionContent = $(".accordion__content"),
		thisGroup,
		speed = 300;

	if (accordion.length) {
		accordionBtn.click(function () {
			if ($(this).closest(accordion).hasClass("accordion--open") == true) {
				$(this).closest(accordion).removeClass("accordion--open");
				$(this).closest(accordion).find(accordionContent).slideUp(speed);
			} else {
				thisGroup = $(this).closest(accordion).data("id");
				$(".accordion[data-id=" + thisGroup + "]").removeClass("accordion--open");
				$(this).closest(accordion).toggleClass("accordion--open");
				$(".accordion[data-id=" + thisGroup + "]").find(accordionContent).slideUp(speed);
				$(this).closest(accordion).find(accordionContent).slideDown(speed);
			}
		});
	}
});
