$(document).ready(function () {

	const ua = navigator.userAgent;
	const os = /Android/.test(ua)
		? 'android'
		: /iPhone/.test(ua)
			? 'ios'
			: /iPod/.test(ua)
				? 'ios'
				: /iPad/.test(ua)
					? 'ios'
					: 'default';
	const bodyelement = document.getElementsByTagName('BODY')[0];
	switch (os) {
		case 'android':
			bodyelement.classList.add('android');
			break;
		case 'ios':
			bodyelement.classList.add('ios');
			break;
		default:
			bodyelement.classList.add('default');
	}

});
