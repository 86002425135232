$(document).ready(function () {
	// Emphasize current url anchor on menu
	emphasizeCurrentUrl()
	preventEnterSubmit()
	uncoverInvalidOnSubmit()

	//autocomplete search
	//$('input[name="search"]').on('keyup', debounce(autocomplete, 1000))

	// Search module =====
	hideNavSearchIfHasSearchPart()
	submitSearchFormOnEnterKey()
	loadMoreSearchResults()
	focusOnSearchBox()
})

function emphasizeCurrentUrl () {
	var foundElement = findMatchingUrl('.header__nav', location.href.split('?', 1)[0])
	if (!foundElement) {
		console.warn('Menu item not found')
		return
	}
	foundElement.style.fontWeight = '600'
}

function findMatchingUrl (searchParentSelector, href) {
	if (!searchParentSelector || !href) return
	href = href.replace('//', '$')
	var idx = href.indexOf('/')
	href = href.slice(idx)
	var searchParent = document.querySelector(searchParentSelector)
	if (!searchParent) return
	return searchParent.querySelector('a[href$="' + href + '"')
}

function preventEnterSubmit () {
	$(window).keydown(function (event) {
		if (event.keyCode == 13) {
			event.preventDefault()
			return false
		}
	})
}

function uncoverInvalidOnSubmit () {
	$('input.xp-submit[type="submit"]').click(function () {
		// Uncover pristine fields
		$(this.form).find(':input').removeClass('pristine')
	})
}

const debounce = (func, wait) => {
	let timeout

	return function executedFunction (...args) {
		const later = () => {
			clearTimeout(timeout)
			func(...args)
		}

		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
	}
}

/*function autocomplete () {
	$.ajax({
		dataType: 'json',
		type: 'GET',
		url: searchServiceUrl,
		data: {
			word: $('input[name="search"]').val(),
			count: 5,
			start: 0
		},
		success: (data) => {
			$('#search-list').empty()
			$.each(data.resultList, function (index, elem) {
				$('#search-list').append('<li><a src="' + elem.url + '">' + elem.title + '<br>' + elem.intro + '</li></a>')
			})
		}
	})
}*/

function clearSearch () {
	$('#clear-search').on('click', function () {
		$('input[name="word"]').val('')
		$('#search-list').empty()
	})
}

function hideNavSearchIfHasSearchPart () {
	const showNavSearch = $('.searchPart').length === 0
	if (!showNavSearch) { $('.search-nav').hide() }
}

function submitSearchFormOnEnterKey () {
	$('input[name="word"]').keyup(function (event) {
		if (event.keyCode == 13) {
			$(this.form).submit()
		}
	})
}

function loadMoreSearchResults () {
	$('#search-load-more').on('click', function () {
		const page = parseInt($('input[name="page"]').val() || '1') + 1
		const word = $('input[name="searchedWord"]').val()

		if (page && word) {
			$.ajax({
				dataType: 'json',
				type: 'GET',
				url: searchServiceUrl,
				data: {
					word,
					page
				},
				success: (data) => {
					if (!data.showLoadMoreButton) { $('#div-search-load-more').toggle() }
					$('input[name="page"]').val(page)
					const contentList = data.contentList || []

					contentList.forEach(content => {
						const contentLink = content.link || {}
						const href = contentLink && contentLink.href
						const target = contentLink.target
						const title = content.title
						const intro = content.intro
						if (href) {
							$('div.searchPart__results').append(`<a href="${href}" target="${target}" class="searchPart__results--item">${title ? `<h2>${title}</h2>` : ''}${intro ? `<p>${intro}</p>` : ''}</a>`)
						}
					})

					if (window.location) {
						const url = new URL(window.location);
						url.searchParams.set('word', word);
						url.searchParams.set('page', page);
						window.history.pushState({}, '', url);
					}
				}
			})
		}
	})
}

let ruleIsSet = false
function focusOnSearchBox () {
	const transition = document.querySelector('.header__nav .search__field');
	const input = $('#search-box-menu-input')
	$('#side-search-nav').on('click', function () {
		if (!ruleIsSet) {
			transition.addEventListener('transitionend', () => {
				if (!input.hasClass('hasFocus')) {
					input.focus()
				}
				input.toggleClass('hasFocus')
			})
			ruleIsSet = true
		}
	})
}
