$(document).ready(function () {
	const mainMenu = $('header.header')
	const btnMenu = $('.btn.header__menu')
	btnMenu.click(function () {
		mainMenu.toggleClass('header--open-mobile')
	})

	const searchButton = $('.header__nav .search a')
	const searchField = $('.search__field')

	searchButton.click(function (event) {
		searchField.toggleClass('active')
	})
})
